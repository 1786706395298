@use '@queries' as *;

.empty {
  padding: 120px 0 0 0;
  align-self: center;

  @include min-1440-break {
    padding: 140px 0 0 0;
  }
  @include min-1920-break {
    padding: 160px 0 0 0;
  }
}
