@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: flex;
  justify-content: center;
  background: var(--cst-bottombar-background);
  box-shadow: var(--elevation-2);
  padding: 12px 14px;
  min-width: 320px;
  border-bottom: 1px solid var(--cst-bottombar-outline);
  margin-bottom: -1px;

  .content {
    max-width: 1023px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    @include min-428-break {
      gap: 12px;
    }
  }

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 16px 28px;
  }

  .top-slot {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bottom-slot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    max-width: 100%;

    @include min-744-break {
      gap: 10px;
    }
  }
}
