@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.info-block {
  display: flex;
  flex-direction: column;
  gap: 1px;

  .info-content {
    display: flex;
    flex-direction: column;
    width: 118px;

    @include min-428-break {
      width: 138px;
    }

    @include min-744-break {
      width: 100%;
      flex: 1;
    }

    .info-title {
      color: var(--cst-bottombar-description);
      width: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;

      @include typography-s;
      @include weight-semi-bold;

      @include min-428-break {
        @include typography-base;
      }

      @include min-744-break {
        @include typography-m;
      }
    }

    .info-price-tags {
      display: flex;
      width: 100%;
      gap: 4px;
      align-items: center;
      .info-price-current {
        color: var(--cst-bottombar-text-price);

        @include typography-m;
        @include weight-bold;

        @include min-428-break {
          @include typography-l;
        }

        @include min-744-break {
          @include typography-xl;
        }
      }

      .info-price-old {
        color: var(--cst-bottombar-text-old-price);
        @include typography-base;
        @include weight-semi-bold;
      }
    }
  }
}
