@use '@queries' as *;

.items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(144px, 1fr));
  justify-content: flex-start;
  gap: 12px;
  height: 100%;

  @include min-1920-break {
    grid-template-columns: repeat(auto-fill, minmax(163px, 1fr));
  }
}
