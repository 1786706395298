@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.advertising-mobile {
  padding: 0 6px 0 14px;

  @include fixedSize(100%, 44px);

  @include min-428-break {
    @include fixedSize(100%, 56px);
    padding: 0 9px;
  }

  .content {
    display: flex;
    gap: 16px;
    align-items: center;

    .text {
      font-family: Fredoka, sans-serif;
      font-style: normal;
      font-weight: 600;
      color: var(--cst-advertising-text);

      line-height: 24px;
      font-size: 16px;

      @include min-428-break {
        line-height: 30px;
        font-size: 20px;
      }
    }
  }

  span[id='adv-left-illustration'] {
    opacity: 0.4;
    display: none;

    @include min-428-break {
      display: block;

      left: -235px;
      top: -1px;
    }

    @include min-1024-break {
      display: block;

      left: -235px;
      top: -1px;
    }
  }

  span[id='adv-right-illustration'] {
    opacity: 0.4;
    right: -183px;
    top: -8px;

    @include min-428-break {
      right: -183px;
      top: 0px;
    }

    @include min-1024-break {
      right: -183px;
      top: 0px;
    }
  }

  div[id='adv-left-slot'] {
  }

  div[id='adv-mid-slot'] {
    justify-content: flex-start;

    @include min-428-break {
      justify-content: center;
    }
  }

  div[id='adv-right-slot'] {
  }
}
