@use '@typography' as *;
@use '@queries' as *;

.header {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .info {
    display: flex;
    align-items: center;
    gap: 8px;

    .title {
      color: var(--cst-top-up-cash-out-method-title);
      @include typography-base;
      @include weight-semi-bold;

      @include min-1440-break {
        @include typography-m;
      }
    }
  }
}

.additional-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
