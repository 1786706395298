@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.sheet {
  min-height: 551px !important;

  @include min-428-break {
    min-height: 580px !important;
  }
}

.sheet-wrapper,
.sheet-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;

  .sheet-btns {
    gap: 6px;

    @include min-428-break {
      gap: 8px;
    }

    .icon,
    span[id='btn-icon'],
    svg {
      @include singleFixedSize(18px);
      @include min-428-break {
        @include singleFixedSize(20px);
      }
    }
  }

  .payment-methods {
    @include hideScrollbar;
    display: grid;
    max-height: 308px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 100%;
    grid-template-columns: repeat(2, minmax(142px, 1fr));
    gap: 8px;

    @include min-428-break {
      grid-template-columns: repeat(2, minmax(196px, 1fr));
    }
  }

  .user-agreement {
    display: inline-block;
    align-items: center;
    text-align: center;
    @include fixedSize(100%, 36px);

    @include min-428-break {
      @include fixedSize(100%, 21px);
    }

    &__text,
    &__link {
      @include typography-s;
      @include weight-medium;

      @include min-428-break {
        @include typography-base;
      }
    }

    &__text {
      color: var(--cst-bottombar-description);
    }

    &__link {
      color: var(--global-purple600);
    }
  }
}
