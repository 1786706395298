@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.empty {
  display: flex;
  padding: 20px 0;
  align-self: center;

  @include min-428-break {
    padding: 34px 0;
  }
  @include min-744-break {
    padding: 46px 0;
  }
}
