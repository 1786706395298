@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: var(--cst-bottombar-background);
  box-shadow: var(--elevation-2);
  padding: 12px 14px;
  min-width: 320px;
  border-bottom: 1px solid var(--cst-bottombar-outline);
  margin-bottom: -1px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 16px 28px;
  }
}

.info {
  display: flex;
  flex-direction: column;

  &-total-amount {
    color: var(--cst-bottombar-text-price);

    @include typography-m;
    @include weight-bold;

    @include min-428-break {
      @include typography-l;
    }

    @include min-744-break {
      @include typography-xl;
    }
  }

  &-quantity {
    color: var(--cst-bottombar-description);
    @include typography-xs;
    @include weight-medium;

    @include min-428-break {
      @include typography-base;
    }

    @include min-744-break {
      @include typography-m;
    }
  }
}

.btn {
  @include fixedSize(158px, 38px);

  border-radius: var(--cornerradius8);

  span[id='button-span-text'] {
    @include typography-s;
    @include weight-semi-bold;

    @include min-428-break {
      @include typography-base;
    }
  }

  @include min-428-break {
    @include fixedSize(188px, 46px);
    border-radius: var(--cornerradius10);
  }

  @include min-744-break {
    @include fixedSize(190px, 54px);
    border-radius: var(--cornerradius12);
  }

  span[id='btn-icon'],
  svg {
    @include singleFixedSize(18px);

    @include min-428-break {
      @include singleFixedSize(20px);
    }

    @include min-744-break {
      @include singleFixedSize(22px);
    }
  }
}
