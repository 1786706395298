@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  justify-content: center;
  background: var(--cst-bottombar-background);
  box-shadow: var(--elevation-2);
  padding: 12px 14px;
  min-width: 320px;

  @include min-428-break {
    gap: 10px;
    padding: 14px;
  }

  @include min-428-break {
    padding: 16px 28px;
  }
}
